const initialState = {
  current: ''
}

const SET_SORT = 'SET_SORT'

export const setSort = current => ({
  type: SET_SORT, current
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SORT:
      return { ...state, current: action.current }
    default:
      return state
  }
}