const config = {
    host: 'https://cms.birdyhomes.com.au',
    frontend: 'https://birdyhomes.com.au',
    //frontend: 'http://localhost:8000',
    fb_app_id: 1274736899593040,
    google_client_id: '441275820398-0pkcku45bdgdttoobb9ol4nno7dkf686.apps.googleusercontent.com',
    //apple_client_id: 'birdyhomes.com.au.tribeca',
    apple_client_id: 'com.birdyhomes.com.au.signin',
    google_map_api_key: 'AIzaSyAsbgo07Tl9O7bCn75OkYq2GCGFtkfxXac',
}
export default config