import axios from "axios"
import Cookies from 'universal-cookie'
import config from '../config/config'
import { cleanUpBoards } from "./account-services"
const cookies = new Cookies()
var days_to_save = 14 * 1000 * 24 * 60 * 60 // 14 days

export const isBrowser = () => typeof window !== "undefined"
export const getUser = () =>{
    var Member = false
    if (typeof window !== "undefined" && window.localStorage) {
        if(window.localStorage.getItem('gatsbyUser')){
            Member = JSON.parse(window.localStorage.getItem('gatsbyUser'))
            //sessionStorage.removeItem("cleanup")
            var isCleanup = sessionStorage.getItem("cleanup")
            if(typeof isCleanup !== 'null' && isCleanup != 1){
                cleanUpBoards(Member)
                Member = JSON.parse(window.localStorage.getItem('gatsbyUser'))
            }
        }
    }
    return Member
}
export const setUser = user =>{
    // cookies.set('gatsbyUser', JSON.stringify(user), { path: '/', expires: new Date(Date.now()+ days_to_save) })
    if (typeof window !== "undefined" && window.localStorage) {
        window.localStorage.setItem('gatsbyUser', JSON.stringify(user))
    }
}
    
export const handleLogin = (data) => {
    var un = btoa(data.email)
    var pw = btoa(data.password)
    var rm = btoa(data.remember)
    var _data = {
        enu: un,
        enp: pw,
        enrm: rm,
        fbid: data.fbid,
        ggid: data.ggid,
        appleid: data.appleid,
        is_private_email: data.is_private_email,
        method: data.method,
        name:  data.name,
        accessToken: data.accessToken,
    }
    
    axios.post(config.host + "/tribecaapi/authenticate", _data)
            .then(response => {
                let _response_data = response.data
                let data_type = (typeof _response_data)
                if (data_type === 'object') {
                    setUser(_response_data)
                    if(typeof window !== 'undefined'){
                        window.location.href = "/account/"
                    }
                }else{
                    let _error_msg = "<p>The provided details don't seem to be correct. Please try again.</p>"
                    document.getElementById('error-message').innerHTML = _error_msg
                    document.getElementById("error-message").style.display = "block"
                    return false
                }
            }).catch(function (error) {
    })
}
export const handleLoginAsUser = (data) => {
    var un = btoa(data.email)
    var pw = data.password
    var rm = btoa(data.remember)
    var _data = {
        enu: un,
        enp: pw,
        enrm: rm,
        type: 'logasuser',
    }
    
    axios.post(config.host + "/tribecaapi/authenticate", _data)
            .then(response => {
                let _response_data = response.data
                let data_type = (typeof _response_data)
                if (data_type === 'object') {
                    setUser(_response_data)
                    if(typeof window !== 'undefined'){
                        window.location.href = "/account/"
                    }
                }else{
                    let _error_msg = "<p>The provided details don't seem to be correct. Please try again.</p>"
                    document.getElementById('error-message').innerHTML = _error_msg
                    document.getElementById("error-message").style.display = "block"
                    return false
                }
            }).catch(function (error) {
    })
}
export const handleLostPassword = (data) => {
    var un = btoa(data.email)
    var _data = {
        enu: un,
        host: config.frontend,
    }
    axios.post(config.host + "/tribecaapi/lostpassword", _data)
            .then(response => {
                let _response_data = response.data
                if(_response_data == 1){
                    document.getElementById("page-content").style.display = 'none'
                    document.getElementById("success-message").style.display = 'block'
                    document.getElementById("submitpasswordreset").innerHTML = 'Sent'
                }
            }).catch(function (error) {
    })
}
export const handleResetPassword = (data) => {
    var pw = data.password
    var cpw = data.confirmpassword
    var id = data.id
    var hash = data.hash
    var _data = {
        pw: pw,
        cpw: cpw,
        id: id,
        hash: hash,
    }
    axios.post(config.host + "/tribecaapi/resetpassword", _data)
            .then(response => {
                let _response_data = response.data
                let data_type = (typeof _response_data)
                if (data_type === 'object') {
                    setUser(_response_data)
                    if(typeof window !== 'undefined'){
                        window.location.href = "/account/"
                    }
                }
            }).catch(function (error) {
    })
}
export const isLoggedIn = () => {
    const user = getUser()
    let isLogged = !!user && !!user.Email
    return isLogged
}
export const logout = callback => {
    if (typeof window !== "undefined" && window.localStorage) {
        window.localStorage.removeItem('gatsbyUser')
        window.location.href = "/"
    }
}