const initialState = {
  current: ''
}

const SET_CURRENT = 'SET_CURRENT'

export const setCurrent = current => ({
  type: SET_CURRENT, current
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT:
      return { ...state, current: action.current }
    default:
      return state
  }
}