const initialState = {
  current: ''
}

const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE'

export const setSearchValue = current => ({
  type: SET_SEARCH_VALUE, current
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_VALUE:
      return { ...state, current: action.current }
    default:
      return state
  }
}